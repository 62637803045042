import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PaymentService from '@/services/payment.service'
import InfoCreditCard from '../../Payment/AddPayment/Common/InfoCreditCard/InfoCreditCard.vue'
import EventBus from '@/util/EventBus'

export default {
    name: 'PlanConfirmSubscriptionUpdateCard',
    components: {
        HeaderTopDashboard,
        InfoCreditCard,
    },
    props: {
        payment: {
            type: Object,
            required: true,
        },
    },
    data () {
        return {
            loading: false,
            showRedirect: false,
            cant: 1,
            subTotalSetup: 0,
            subTotalFee: 0,
            subTotalIva: 0,
            promo: '',
            promotional_code: '',
            userPlanId: 0,
            userSubscriptionId: 0,
            planType: null,
        }
    },
    watch: {
        payment: {
            handler: function () {
                this.calculateSubtotals()
            },
            deep: true,
        },
        locale () {
        },
    },
    computed: {
        locale () {
            return this.$i18n.locale
        },
        paymentIvaLine () {
            const vatDivision = this.payment.paymentDetail?.infoBilling?.billingProfiles[0]?.vatDivision
            if (vatDivision) {
                let line = ''
                for (const [vatDivisionName, vatDivisionValue] of Object.entries(vatDivision)) {
                    line += vatDivisionName + ' ' + vatDivisionValue + '% + '
                }
                return line.slice(0, -2)
            } else {
                return this.payment.invoiceTmp.pc_iva + '% IVA'
            }
        },
    },
    methods: {
        calculateSubtotals () {
            this.calculateSubtotalsSetup(
                this.payment.setup,
                this.cant,
                this.payment.invoiceTmp.iva,
            )
            this.calculateSubtotalsFee(
                this.payment.fee,
                this.cant,
                this.payment.invoiceTmp.iva,
            )
        },
        calculateSubtotalsSetup (amount, cant, iva) {
            this.subTotalSetup = parseFloat(cant) * parseFloat(amount)
            this.subTotalIva += (parseFloat(this.subTotalSetup) * parseFloat(iva)) / 100
        },
        calculateSubtotalsFee (amount, cant, iva) {
            this.subTotalFee = parseFloat(cant) * parseFloat(amount)
            this.subTotalIva += (parseFloat(this.subTotalFee) * parseFloat(iva)) / 100
        },
        confimCreditCardPaymentMethod () {
            const data = {
                amount: this.parseAmount(this.payment.amount),
                infobillingId: this.payment.paymentDetail.infoBilling.billingProfiles[0].id,
                subscribed_card: this.payment.creditCardOptions.subscribed_card,
                rememberCreditCard: this.payment.creditCardOptions.rememberCreditCard,
                promo: this.promo,
                invoiceTmpId: this.payment.invoiceTmp.id,
                userPlanId: this.userPlanId,
                userSubscriptionId: this.userSubscriptionId,
            }
            PaymentService.confimCreditCardPaymentMethod(data)
                .then(
                    (response) => {
                        this.payment.invoiceTmp = response.invoiceTmp
                        this.payment.urlCreditCardPay = response.url
                    },
                    (error) => {
                        console.log('error', error)
                    },
                )
        },
        viewUrlPay () {
            this.loading = true
            this.showRedirect = true
            setTimeout(function (scope) {
                scope.loading = false
                window.location.href = scope.payment.urlCreditCardPay
            }, 1000, this)
        },
        cancelStep () {
            this.payment.step = 1
        },
    },
    mounted () {
        console.log('payment', this.payment)
    },
}
