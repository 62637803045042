import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import PlanSubscriptionUpdateCard from './PlanSubscriptionUpdateCard.vue'
import PlanConfirmSubscriptionUpdateCard from './PlanConfirmSubscriptionUpdateCard.vue'

export default {
    name: 'SubscriptionUpdateCardNeeded',
    props: {
        payment: {
            type: Object,
            required: true,
        },
    },
    components: {
        HeaderTopDashboard,
        PlanSubscriptionUpdateCard,
        PlanConfirmSubscriptionUpdateCard,
    },
    data () {
        return {
            ready: false,
        }
    },
    computed: {
    },
    methods: {
    },
    created () {
    },
    mounted () {
    },
}
